/**
 * Timetastic
 * About Us page
 */
import React, { useEffect } from "react"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components// Components
import CaseStudies from "src/components/caseStudies"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/about.scss"
import "src/styles/animations/about.scss"

// SEO
const title = "About Timetastic - Giving you a better way to book time off"
const description =
  "Started in 2012, Timetastic is the simple employee PTO tracking system used by over 100,000 people around the world."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO
        title={title}
        useTitleTemplate={false}
        description={description}
        hrefLangs={{
          EnHref: "https://timetastic.co.uk/about/",
          UsaHref: "https://timetastic.us/about/",
          UkHref: "https://timetastic.co.uk/about/",
        }}
      />
      <Layout>
        <Header />
        <FooterCta />
        <div className="u-inner">
          {/* <img
            className="c-about-intro__decoration c-about-intro__decoration--three"
            alt="Small green blob"
            height="190"
            src="/images/about/small-green-blob.png"
            width="186"
          /> */}
        </div>
        <main className="c-about-main">
          <section className="c-section c-about-intro">
            <div className="u-inner u-inner--800 is-unanimated">
              <h1 className="h h1 c-about-intro__title">
                Fantastic,{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  Timetastic!
                </span>
              </h1>
              <p className="c-about-intro__summary">
                We’ve replaced paper forms and messy spreadsheets in thousands
                of businesses like yours.
              </p>
              <p>
                Keeping track of who’s off and when is pretty easy when there’s
                only two or three of you to think about.
              </p>
              <p>
                But as your team grows, or you get multiple teams to manage,
                things get more complicated. Every time someone wants a day off
                you’ve got paperwork to sign-off and clunky spreadsheets to
                update.
              </p>
              <p>
                We regularly come across people ’making do’ with spreadsheets
                and paperwork. We see people not taking their PTO entitlement —
                which is really important for their health, wellbeing and
                productivity at work.
              </p>
              <p>
                But it needn’t be hard, because we made an app to make your life
                a touch easier and your team a little happier.
              </p>
            </div>
          </section>
          <section
            className="c-section u-text-center u-text-left-mobile c-about-reviews-section"
            id="reviews"
          >
            <div className="u-inner u-inner--l">
              <div className=" is-unanimated">
                <h2 className="h h2 c-about-features__title">
                  Happy customers
                </h2>
                <p>98% of reviewers recommend Timetastic</p>
              </div>
              <div className="c-about-features">
                <div className="c-about-feature is-unanimated">
                  <div className="c-about-feature__body">
                    <h3 className="h h3 c-about-feature__title">
                      “A breath of fresh air”
                    </h3>
                    <blockquote className="c-about-feature__blockquote">
                      <p>
                        “Timetastic is the undisputed champion of holiday
                        booking. Our team is hooked; one member even said they
                        logged in just to admire the interface! HR-type
                        platforms are littered with clunky solutions, Timetastic
                        is a breath of fresh air. Its user experience is so
                        intuitive, it's like it reads your mind and books your
                        holiday before you even think about it.”
                      </p>
                    </blockquote>
                  </div>
                  <cite className="c-about-feature__cite">
                    <a
                      className="c-reviews-list__link"
                      href="https://www.reviews.io/company-review/store/timetastic/19394784"
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                    >
                      <span>Gav Winter</span>
                    </a>
                  </cite>
                </div>
                <div className="c-about-feature is-unanimated">
                  <div className="c-about-feature__body">
                    <h3 className="h h3 c-about-feature__title">
                      “An invaluable asset”
                    </h3>
                    <blockquote className="c-about-feature__blockquote">
                      <p>
                        “I highly recommend Timetastic for streamlined leave
                        management. With 100+ employees submitting and approving
                        time off requests via intuitive desktop or mobile apps,
                        scheduling is very easy... By saving time, reducing
                        admin, and improving company culture around time off,
                        Timetastic is an invaluable asset that scales with our
                        growing business.”
                      </p>
                    </blockquote>
                  </div>
                  <cite className="c-about-feature__cite">
                    <a
                      className="c-reviews-list__link"
                      href="https://www.reviews.co.uk/company-review/store/timetastic/19573595"
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                    >
                      <span>Scott Shields</span>
                    </a>
                  </cite>
                </div>
              </div>
            </div>
          </section>

          <FreeTrial
            body="Take a look around Timetastic at your own pace, totally free and without commitment. If you’ve got questions, just get in touch with our support team and we’ll guide you."
            buttonCopy="Start free trial"
            coloured={false}
            title="Want to try Timetastic?"
            className="c-about-free-trial"
            trackCtaLabel="AboutSignUpHeaderUS"
          />
          <section className="c-section">
            <div className="u-inner is-unanimated">
              <h2 className="h h2 u-text-centre-desktop">
                Proud to be part
                <br /> of The Citation Group
              </h2>
              <p className="u-text-centre-desktop">
                <a
                  className="u-em-link"
                  href="https://thecitationgroup.com"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("CitationGroup")
                  }}
                >
                  <span>Citation</span>
                </a>{" "}
                are a large group that covers all manner of services and
                software for small to medium businesses. They exist to save you
                from the pain of bureaucracy and help you run an efficient
                business. Things like HR, Employment Law, Training, Health &
                safety, ISO certifications, Cyber security.
              </p>
            </div>
          </section>
          <section className="c-section">
            <div className="u-inner u-inner--l is-unanimated">
              <div className="u-inner">
                <h2 className="h h2 u-text-centre-desktop">
                  Corporate citizenship
                </h2>
                <p className="u-text-centre-desktop">
                  ’Corporate citizenship’ can sound a bit grand, but being a
                  profitable business doesn’t mean we can’t have a positive
                  influence on other people’s lives or the environment we live
                  in.
                </p>
              </div>
              <div className="c-about-charity-blocks">
                <div className="c-about-charity-block c-about-charity-block__bcorp is-unanimated">
                  <div className="c-about-charity-block__body">
                    <h3 className="h h3">B Corp&trade;</h3>
                    <p>
                      Certified B Corporations are a new kind of business that
                      are legally required to consider the impact of their
                      decisions on their workers, customers, suppliers,
                      community, and the environment.
                    </p>
                  </div>
                  <div className="u-text-right">
                    <a
                      href="https://www.bcorporation.net/en-us/find-a-b-corp/company/timetastic"
                      onClick={() => {
                        trackCta("BCorp")
                      }}
                      rel="nofollow noreferrer noopener"
                    >
                      <img
                        alt="Certified B Corp"
                        src="/images/about/b-corp-logo.svg"
                        width="76px"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
                <div className="c-about-charity-block c-about-charity-block__1percent">
                  <div className="c-about-charity-block__body">
                    <h2 className="h h3">1% for the Planet</h2>
                    <p>
                      1% for the Planet is a global movement inspiring
                      businesses and individuals to support environmental
                      solutions through annual memberships and everyday actions.
                    </p>
                  </div>
                  <div className="u-text-right">
                    <a
                      href="https://directories.onepercentfortheplanet.org/profile/timetastic-ltd"
                      onClick={() => {
                        trackCta("OnePerCentForThePlanet")
                      }}
                      rel="nofollow noreferrer noopener"
                    >
                      <img
                        alt="1% for the Planet"
                        src="/images/about/1-percent-logo.svg"
                        width="106px"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
                <div className="c-about-charity-block c-about-charity-block__100trees is-unanimated">
                  <div className="c-about-charity-block__body">
                    <h3 className="h h3">100 Trees Club</h3>
                    <p>
                      Significant tree planting forms part of the UK’s plan to
                      get to zero carbon by 2050. And by significant we mean
                      planting 12 million trees a year!
                    </p>
                    <p>
                      Our contribution is being part of the 100 Trees Club to
                      help plant some of the 50 million trees planned as part of
                      the Northern Forest.
                    </p>
                  </div>
                  <div className="u-text-right">
                    <a
                      href="https://www.cityoftrees.org.uk/100treesclub"
                      onClick={() => {
                        trackCta("100TreesClub")
                      }}
                      rel="nofollow noreferrer noopener"
                    >
                      <img
                        alt="100 trees club"
                        src="/images/about/100-trees-club.png"
                        width="237px"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
                <div className="c-about-charity-block c-about-charity-block__smartworks is-unanimated">
                  <div className="c-about-charity-block__body">
                    <h3 className="h h3">Smart Works</h3>
                    <p>
                      We were introduced to Smart Works back in February 2018
                      and have since become a key supporter of their Manchester
                      centre. Smart Works helps unemployed women get back into
                      work by giving them expert interview coaching and
                      providing a high quality outfit.
                    </p>
                    <p>
                      Over 60% of their clients land a job within their first
                      month, and so far they’ve helped over 15,000 women all
                      over UK. We’re delighted to be involved.
                    </p>
                  </div>
                  <div className="u-text-right">
                    <a
                      href="https://greatermanchester.smartworks.org.uk/2019/02/11/timetastic-supports-greater-manchester/"
                      onClick={() => {
                        trackCta("SmartWorks")
                      }}
                      rel="nofollow noreferrer noopener"
                    >
                      <img
                        alt="Smart Works"
                        src="/images/about/smart-works.svg"
                        width="128px"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className=" is-unanimated">
                <h2 className="h h2 u-text-centre u-text-left-mobile">
                  See what our{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    customers
                  </span>{" "}
                  think
                  <svg
                    className="c-about-customers-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 42"
                    height="42"
                    width="48"
                  >
                    <linearGradient
                      id="a"
                      x1="9.925"
                      x2="38.318"
                      y1="27.035"
                      y2="-1.358"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="15%" stopColor="#e5007e" />
                      <stop offset="50%" stopColor="#995bc4" />
                      <stop offset="85%" stopColor="#007ac9" />
                    </linearGradient>
                    <path
                      fill="url(#a)"
                      d="M24.1 40.5c-.5 0-1-.2-1.4-.6L4.1 20.7C1.9 18.3.8 15.5.8 12.5.8 6.7 5.2 1.7 11.1.9c.5-.1 1-.1 1.5-.1 3.8 0 7.4 1.8 9.6 4.8L24 8.1l1.8-2.5C28 2.5 31.6.7 35.5.7c.5 0 1 0 1.5.1 5.9.8 10.3 5.8 10.3 11.7 0 3-1.2 6-3.3 8.2L25.5 39.9c-.4.4-.9.6-1.4.6z"
                    />
                    <path
                      fill="#2b2a29"
                      d="M24 41.2c-.7 0-1.5-.3-2-.8L3.5 21.2C1.3 18.9 0 15.8 0 12.5 0 6.3 4.7.9 11 .1c4.6-.6 9.2 1.4 11.9 5.1L24 6.8l1.1-1.6C27.8 1.5 32.4-.5 37 .1c6.2.8 11 6.2 11 12.4 0 3.3-1.2 6.3-3.5 8.7L26 40.4c-.5.5-1.2.8-2 .8zM12.6 1.8c-.5 0-.9 0-1.4.1-5.4.7-9.4 5.3-9.4 10.6 0 2.8 1.1 5.4 3 7.5l18.6 19.2c.4.4 1 .4 1.4 0L43.2 20c1.9-2 3-4.7 3-7.5 0-5.4-4-10-9.4-10.7-3.9-.5-7.9 1.2-10.2 4.4l-1.9 2.6c-.1.2-.4.3-.6.4-.3 0-.7-.1-.8-.4l-1.9-2.6c-2-2.7-5.3-4.4-8.8-4.4z"
                    />
                  </svg>
                </h2>
              </div>
              <CaseStudies />
            </div>
          </section>
          <FreeTrialAlt
            body="Timetastic integrates with your favourite apps: Google Calendar, G Suite, Apple Calendar, Slack and Outlook Calendar."
            buttonCopy="Start free trial"
            title="Get one month free!"
            trackCtaLabel="AboutSignUp"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
